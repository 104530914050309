<template>
    <section class="d-middle justify-center h-100">
        <template v-if="[1].indexOf(pedido.estado) > -1">
            <div v-if="$can('botones_abastecimiento_confirmar_pedido')" class="btn-general br-8 mx-2 f-14 px-2" @click="rechazarPedido">
                Rechazar
            </div>
            <div v-if="$can('botones_abastecimiento_confirmar_pedido')" class="btn-general br-8 f-14 px-2" @click="confirmarPedido">
                Confirmar
            </div>
        </template>
        <template v-if="pedido.id_pedido == null">
            <el-tooltip class="item" effect="light" :content="`Hablar con el ${$config.vendedor}`" placement="bottom">
                <el-badge :value="pedido.cant_mensajes" :hidden="pedido.cant_mensajes<=0" class="item btn-action border" @click="verChat">
                    <i class="icon-message-text cr-pointer f-20" />
                </el-badge>
            </el-tooltip>
            <div v-show="pedido.estado === 2 && $can('botones_abastecimiento_alistar_pedido')" class="btn-general cr-pointer br-8 mx-2 bg-general text-white px-2 py-1" @click="$refs.modalAlistarPedido.toggle()">
                Alistar
            </div>
            <div v-show="pedido.estado === 201 && $can('botones_abastecimiento_alistar_pedido')" class="btn-general cr-pointer br-8 mx-2 bg-general text-white px-2 py-1" @click="$refs.modalEmpacarPedido.toggle()">
                Empacar
            </div>
            <div v-show="pedido.estado === 202 && $can('botones_abastecimiento_enviar_pedido')" class="btn-general cr-pointer br-8 mx-2 bg-general text-white px-2 py-1" @click="verificar_prodructos_para_envio">
                Enviar
            </div>
            <div v-show="pedido.estado === 3 && $can('botones_abastecimiento_finalizar_pedido')" class="btn-general cr-pointer br-8 mx-2 bg-general text-white px-2 py-1" @click="FinalizarPedido">
                Finalizar
            </div>
            <el-dropdown trigger="click" @command="accionBoton">
                <div class="btn-action br-8 border">
                    <i class="icon-dots-horizontal f-20" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-show="[2,201,202].indexOf(pedido.estado) > -1 && $can('botones_abastecimiento_editar_datos_pedido')" :command="1">
                        <div class="row mx-0 align-items-center">
                            <i class="icon-calendar-clock f-20" />
                            <span>Editar Datos Pedido</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="$can('botones_abastecimiento_cancelar_pedido')" :command="0">
                        <div class="row mx-0 align-items-center">
                            <i class="icon-cancel-circled-outline f-20" />
                            <span>Cancelar Pedido</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item v-show="pedido.estado == 3 && $can('botones_abastecimiento_devolver_productos')" :command="2">
                        <div class="row mx-0 align-items-center">
                            <i class="icon-devolver f-20" />
                            <span>Devolver Productos</span>
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>
        <template v-else>
            <div v-if="pedido.datos_pedido.estado == 11" class="col px-0 rompe-palabras">
                <p class="f-14 text-general ">Esperando Confirmacion del {{ $config.cliente }}</p>
            </div>
            <el-tooltip class="item" effect="light" :content="`Pedido correspondiente a un ${$config.cliente}` " placement="bottom">
                <i class="icon-cliente text-general f-20" />
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="`Hablar con el ${$config.vendedor}`" placement="bottom">
                <el-badge :value="pedido.cant_mensajes" :hidden="pedido.cant_mensajes<=0" class="item btn-action br-8 border mx-2">
                    <i class="icon-message-text cr-pointer f-18" @click="verChat" />
                </el-badge>
            </el-tooltip>
            <template v-if="pedido.datos_pedido.estado != 110 && pedido.datos_pedido.estado != 11 ">
                <div v-show="pedido.estado === 2 && $can('botones_abastecimiento_alistar_pedido')" class="br-8 btn-general mx-2 cr-pointer bg-general text-white px-2 py-1" @click="$refs.modalAlistarPedido.toggle()">
                    Alistar
                </div>
                <div v-show="pedido.estado === 201 && $can('botones_abastecimiento_alistar_pedido')" class="br-8 btn-general mx-2 cr-pointer bg-general text-white px-2 py-1" @click="$refs.modalEmpacarPedido.toggle()">
                    Empacar
                </div>
                <div v-show="pedido.estado === 202 && $can('botones_abastecimiento_enviar_pedido')" class="br-8 btn-general mx-2 cr-pointer bg-general text-white px-2 py-1" @click="verificar_prodructos_para_envio">
                    Enviar
                </div>
                <div v-show="pedido.estado === 3 && $can('botones_abastecimiento_finalizar_pedido')" class="br-8 btn-general mx-2 cr-pointer bg-general text-white px-2 py-1" @click="FinalizarPedido">
                    Finalizar
                </div>
            </template>
            <div v-show="pedido.datos_pedido.estado == 110 && $can('botones_abastecimiento_gestionar_productos')" class="btn-general mr-2 px-2 br-8 bg-general text-white" @click="$refs.modalFinCambios.toggle()">
                Fin Cambios
            </div>
            <el-dropdown trigger="click" @command="accionBoton">
                <div class="btn-action br-8 border">
                    <i class="icon-dots-vertical f-20" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-show="[2,201,202].indexOf(pedido.estado) > -1 && $can('botones_abastecimiento_editar_datos_pedido')" :command="1">
                        <i class="icon-calendar-clock f-20" />
                        <span>Editar Datos Pedido</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="$can('botones_abastecimiento_cancelar_pedido')" :command="0">
                        <i class="icon-cancel-circled-outline f-20" />
                        <span>Cancelar Pedido</span>
                    </el-dropdown-item>
                    <el-dropdown-item v-show="pedido.estado == 3 && $can('botones_abastecimiento_devolver_productos')" :command="2">
                        <i class="icon-devolver f-20" />
                        <span>Devolver Productos</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>
        <!-- Partials -->
        <modalConfirmar
        ref="modalAlistarPedido"
        titulo="Alistar Pedido"
        mensaje="¿Desea empezar a alistar el pedido?" icon="Alistar"
        adicional="Alistar"
        no-aceptar
        @adicional="confirmar_cambio_estado(201)"
        />
        <modalConfirmar
        ref="modalEmpacarPedido"
        titulo="Enviar Pedido"
        mensaje="¿Desea pasar el pedido a empacado?" icon="bicycle-l"
        @guardar="confirmar_cambio_estado(202)"
        />
        <modalConfirmar
        ref="modalFinCambios"
        titulo="Finalizar Cambios"
        :mensaje="`¿Desea enviar los cambios del pedido al ${$config.cliente}?`" icon="grocery"
        @guardar="finalizar_edicion_pedido_directo()"
        />
        <modal-finalizar-pedido ref="modalFinalizarPedido" @credito="modalCredito(true,2)" />
        <modal-stock-bajo ref="modalStockBajo" />
        <modal-chat ref="modalChat" :pedido="pedido" :leechero="cliente" @actualizarIcono="actualizarIconoChat" />
        <modal-cancelar-pedido ref="modalCancelarPedido" @accion-cancelar="accion_cancelar" />
        <modal-devolver-prod ref="modalDevolverProd" :pedido="pedido" @update="recalcular_pedido" />
        <modal-editar-pedido ref="modalEditarPedido" :editar="editar" :confirmacion="confirmar_pedido" @accion-editar="accion_editar" />
        <modal-enviar-pedido ref="modalEnviarPed" @accion-enviar="accion_enviar" />
        <modalRechazarPedido ref="modalRechazarPedido" @accion-rechazar="accion_rechazar" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'

export default {
    components: {
        modalFinalizarPedido: () => import('../pedidos/partials/modalFinalizarPedido'),
        modalRechazarPedido: () => import('../pedidos/partials/modalRechazarPedido'),
        modalStockBajo: () => import('../pedidos/partials/modalStockBajo'),
        modalDevolverProd: () => import('../pedidos/partials/modalDevolverProducto'),
        modalCancelarPedido: () => import('../pedidos/partials/modalCancelarPedido'),
        modalEditarPedido: () => import('../pedidos/partials/modalEditarPedido'),
        modalEnviarPedido: () => import('../pedidos/partials/modalEnviarPedido'),

    },
    data(){
        return{
            confirmar_pedido:false,
            editar: false,
        }
    },
    computed: {
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            productos: 'pedidos/pedidos_admin/productos',
        })
    },
    methods: {
        async verificar_prodructos_para_envio(){
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                if(this.productos.length === 0){
                    this.notificacion('Alerta','Debe agregar almenos un (1) producto','warning')
                    return
                }

                const {data} = await Pedidos.verificar_productos(this.id_pedido)

                if(data.agotados > 0){
                    this.$store.commit('pedidos/pedidos_admin/set_productos_stock_bajo', data.productos)
                    this.$refs.modalStockBajo.toggle()
                    return
                }

                this.$refs.modalEnviarPed.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        accionBoton(key){
            switch (key){
            case 1:
                this.editar_pedido()
                break;
            case 2:
                this.devolverProducto()
                break;
            case 0:
                this.cancelarPedido()
                break;

            default:
                break;
            }
        },
        modalCredito(res,opcion=1){
            if(opcion === 1){
                this.$refs.modalOfrecerCredito.toggle()
                if(res){
                    this.$refs.detallePedido.otorgarCredito()
                }
            }else if(opcion === 2){
                this.$emit('credito')
            }
        },
        verChat(){
            this.pedido.cant_mensajes = 0
            this.$refs.modalChat.toggle(this.id_pedido, 5, false , 1) // 5 = tipo pedido admin
        },
        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos/set_pedido', {...this.pedido, cant_mensajes: 0})
        },
        FinalizarPedido(){
            this.$refs.modalFinalizarPedido.toggle()
        },
        accion_cancelar(){
            this.$emit('update')
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        },
        recalcular_pedido(){
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))
        },
        cancelarPedido(){
            this.$refs.modalCancelarPedido.toggle()
        },
        async editar_pedido(){
            this.confirmar_pedido = false
            this.editar = true
            this.$refs.modalEditarPedido.toggle()
        },
        accion_editar(){
            this.$emit('update')
        },
        async confirmar_cambio_estado(estado){
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                const {data} = await Pedidos.confirmar_cambio_estado(this.id_pedido,estado)
                switch (estado){
                case 201:
                    this.$refs.modalAlistarPedido.toggle()
                    break;
                case 202:
                    this.$refs.modalEmpacarPedido.toggle()
                    break;
                case 3:
                    this.$refs.modalEnviarPedido.toggle()
                    break;

                default:
                }

                this.recalcular_pedido()
                this.notificacion('Pedido Actualizado','','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        accion_enviar(){
            // if(this.pedido.pago_estado == 1){
            //     this.$refs.modalOfrecerCredito.toggle()
            // }
            this.recalcular_pedido()
        },
        async finalizar_edicion_pedido_directo(){
            try {
                const {data} = await Pedidos.finalizar_edicion_pedido_directo(this.id_pedido)
                this.recalcular_pedido()
                this.notificacion('Éxito','Los cambios fueron enviados al cliente. Esperando confirmación...','success')
                this.$refs.modalFinCambios.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        rechazarPedido(){
            this.$refs.modalRechazarPedido.toggle()
        },
        confirmarPedido(){
            this.confirmar_pedido = true
            this.editar = false
            this.$refs.modalEditarPedido.toggle()
        },
        accion_rechazar(){
            this.$emit('update')
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        },
    }
}
</script>
<style lang="scss" scoped>
.text-state{
    max-height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
